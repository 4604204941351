<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Audit logs</h1>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <div
        v-if="!!auditLogs"
        class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
        style="width: 100%"
      >
        <table v-if="auditLogs.data && auditLogs.data.length" class="table table--striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Request Path</th>
              <th>Action Type</th>
              <th>Request Body</th>
              <th>Response</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="log in auditLogs.data" :key="log.id">
              <td>{{ log.createdAt | moment_l }}</td>
              <td>{{ log.user.email }}</td>
              <td>{{ log.path }}</td>
              <td>{{ log.type }}</td>
              <td class="mw-200" @click="showFullBody(log.requestBody)">{{ log.requestBody }}</td>
              <td class="mw-200" @click="showFullBody(log.responseBody)">{{ log.responseBody }}</td>
              <td>{{ log.status }}</td>
            </tr>
          </tbody>
        </table>

        <div class="text-center mt-32">
          <p>{{ auditLogs.total | format_order_count_text }} in total</p>

          <pagination
            v-if="isNextPage(auditLogs.total)"
            v-model="params.page"
            :page-count="getPageCount(auditLogs.totalPages, auditLogs.total)"
            :page-range="5"
            :click-handler="getAuditLogs"
          />
        </div>
      </div>

      <p v-else>No data found</p>
    </div>

    <modal v-if="isShowFullBodyModalOpen" modalClass="modal modal--md" :open.sync="isShowFullBodyModalOpen">
      <h3 class="headline--sm text-bold mb-16">Request / Response Body</h3>
      <p class="modal-body">
        {{ showFullBodyContent }}
      </p>
    </modal>
  </div>
</template>

<script>
import AuditLogService from '@/services/audit-log'
import pagination from '@/mixins/pagination'
import Pagination from '@/components/Pagination'
import Modal from '@/components/Modal'
import { authorizationHeader } from '@/store/support/authorization'

export default {
  mixins: [pagination],

  data() {
    return {
      auditLogs: {},
      params: {
        page: 1,
        perPage: 10
      },
      isShowFullBodyModalOpen: false,
      showFullBodyContent: ''
    }
  },

  components: {
    Pagination,
    Modal
  },

  methods: {
    getAuditLogs() {
      AuditLogService.getAuditLogs(this.params, authorizationHeader()).then(({ data }) => {
        this.auditLogs = data.result
      })
    },

    showFullBody(body) {
      if (!body) return

      this.showFullBodyContent = body
      this.isShowFullBodyModalOpen = true
    }
  },

  async created() {
    await this.getAuditLogs()
  }
}
</script>

<style lang="scss" scoped>
.mw-200 {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.modal-body {
  word-break: break-all;
}
</style>
