<template>
  <div :class="['modal-wrapper', { active: open }]">
    <!-- <div class="modal-wrapper__scroller"> -->
    <span class="modal-overlay" @click="toggle"></span>

    <div :class="['modal', modalClass]">
      <span class="modal__close" @click="toggle">
        <toko-close-icon />
      </span>

      <slot />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import modal from '@/mixins/modal'
import TokoCloseIcon from '@/ui/toko-icons/TokoClose'

export default {
  name: 'Modal',

  mixins: [modal],

  props: {
    modalClass: {
      type: String,
      required: false
    }
  },

  components: {
    TokoCloseIcon
  }
}
</script>
