import apiCaller from './api-caller'

const RESOUCES = {
  auditLogs: '/audit-log'
}

export default {
  getAuditLogs(params, headers) {
    return apiCaller({ method: 'GET', url: `${RESOUCES.auditLogs}/list`, params, headers })
  }
}
