<template>
  <nav class="pagination-wrapper">
    <ul class="pagination">
      <li :class="['pagination__prev pagination__item', firstPageSelected && 'disabled']">
        <a
          @click="prevPage()"
          @keyup.enter="prevPage()"
          class="link pagination__link"
          :tabindex="firstPageSelected ? -1 : 0"
        >
          <toko-prev-icon />
        </a>
      </li>

      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="[
          'pagination__item',
          page.selected && 'active',
          page.disabled && 'disabled',
          page.breakView && 'pagination__break'
        ]"
      >
        <a v-if="page.breakView" class="link pagination__link" tabindex="0">...</a>
        <a v-else-if="page.disabled" class="pagination__link" tabindex="0">{{ page.content }}</a>
        <a
          v-else
          @click="handlePageSelected(page.index + 1)"
          @keyup.enter="handlePageSelected(page.index + 1)"
          class="link pagination__link"
          tabindex="0"
          >{{ page.content | number_with_commas }}</a
        >
      </li>

      <li :class="['pagination__next pagination__item', lastPageSelected && 'disabled']">
        <a
          @click="nextPage()"
          @keyup.enter="nextPage()"
          class="link pagination__link"
          :tabindex="lastPageSelected ? -1 : 0"
        >
          <toko-next-icon />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import TokoPrevIcon from '@/ui/toko-icons/TokoPrev'
import TokoNextIcon from '@/ui/toko-icons/TokoNext'

export default {
  name: 'Pagination',

  data() {
    return {
      innerValue: 1,
      marginPages: 1
    }
  },

  props: {
    value: {
      type: Number
    },

    pageCount: {
      type: Number,
      required: true
    },

    clickHandler: {
      type: Function,
      default: () => {}
    },

    pageRange: {
      type: Number,
      default: 3
    }
  },

  components: {
    TokoPrevIcon,
    TokoNextIcon
  },

  computed: {
    selected: {
      get: function() {
        return this.value || this.innerValue
      },

      set: function(newValue) {
        this.innerValue = newValue
      }
    },

    pages() {
      let items = {}
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1
          }
          items[index] = page
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2)

        let setPageItem = index => {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1
          }

          items[index] = page
        }

        let setBreakView = index => {
          let breakView = {
            disabled: true,
            breakView: true
          }

          items[index] = breakView
        }

        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i)
        }

        // 2nd - loop thru selected range
        let selectedRangeLow = 0
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1
        }

        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i)
        }

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1)
        }

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1)
        }

        // 3rd - loop thru high end of margin pages
        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
          setPageItem(i)
        }
      }

      return items
    },

    firstPageSelected() {
      return this.selected === 1
    },

    lastPageSelected() {
      return this.selected === this.pageCount || this.pageCount === 0
    }
  },

  methods: {
    handlePageSelected(selected) {
      if (this.selected === selected) return

      this.innerValue = selected
      this.$emit('input', selected)
      this.clickHandler(selected)
    },

    prevPage() {
      if (this.selected <= 1) return

      this.handlePageSelected(this.selected - 1)
    },

    nextPage() {
      if (this.selected >= this.pageCount) return

      this.handlePageSelected(this.selected + 1)
    }
  }
}
</script>
